import { solidstate } from ":shared/utils/state-manager";
import type { Setting } from "./_model";
import { createMemo } from "solid-js";

export const $platform_settings = solidstate.createGlobal(undefined as Setting[]);

function getSettingsFor(key: Setting["key"]) {
  const all_settings = $platform_settings.accessor();
  let result: { exist: boolean; values?: Setting; locked?: boolean } = { exist: false, locked: true }; // locking by default
  if (all_settings !== undefined && all_settings !== null && Array.isArray(all_settings) && all_settings.length > 0) {
    const key_settings = all_settings.find((x) => x.key === key);
    if (key_settings !== undefined && key_settings !== null) {
      result.exist = true;
      result.values = JSON.parse(JSON.stringify(key_settings)) as Setting;
    }
  }
  if (!result.exist) {
    console.error(`platform settings key not found ${key}`);
  } else if (result.values.active !== undefined && result.values.active !== null) {
    result.locked = !result.values.active;
  }
  return result;
}

export const actions = {
  getSettingsFor,
};

export const hooks = {
  useSettings: (key: Setting["key"]) => {
    return createMemo(() => {
      return getSettingsFor(key);
    });
  },
};
